// userProfileStore.js
import { writable } from 'svelte/store';

// Initialize the user profile store with an initial value of null
const userProfile = writable(null);

function updateUserProfile(payload) {
    userProfile.update(current => {
        return {
            ...current,
            ...payload
        }
    })
}

export { 
    userProfile,
    updateUserProfile
};